import { TIME_ZONES } from "./Constants/TimeZones"
import { CONFIG_SW_STATUS } from "./GlobalConstants"

const {
  zonedTimeToUtc,
  utcToZonedTime,
  format: formatTimeZone
} = require("date-fns-tz")

export const throttle = (func, limit) => {
  let inThrottle
  return function () {
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => (inThrottle = false), limit)
    }
  }
}
export const deviceORQueryFormatter = (arr) => {
  const groupByType = {}

  arr.forEach((item) => {
    if (Object.hasOwn(groupByType, item.type)) {
      groupByType[item.type].push(item.label)
    } else {
      groupByType[item.type] = [item.label]
    }
  })

  const labelList = Object.entries(groupByType).map(([type, labels]) => ({
    labels,
    type
  }))
  return labelList
}

export const toOrBuild = (type, value) => {
  const arr = value.filter((ele) => ele.type === type)[0]?.labels
  return arr?.length && arr.toString().replaceAll(",", " || ")
}

export const getUniqueObjects = (array, uniqueKeys) => {
  if (!uniqueKeys && array.length > 0) {
    uniqueKeys = Object.keys(array[0])
  }
  let unique = []
  for (const element of array) {
    if (
      !unique.find((u) =>
        uniqueKeys.every((eachKey) => element[eachKey] == u[eachKey])
      )
    ) {
      unique.push(element)
    }
  }
  return unique
}

export const arrayGroupBy = (arr, key) => {
  return arr.reduce(function (acc, obj) {
    let propertyKey = obj[key]
    if (!acc[propertyKey]) {
      acc[propertyKey] = []
    }
    acc[propertyKey].push(obj)
    return acc
  }, {})
}

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const severityCustomSortingOrder = (rowA, rowB) => {
  const value = (A) => {
    if (A === "low") {
      return 1
    } else if (A == "moderate") {
      return 2
    } else if (A === "critical") {
      return 3
    }
    return 0
  }

  const Anum = value(rowA.original?.["severity"]?.toLowerCase())
  const Bnum = value(rowB.original?.["severity"]?.toLowerCase())

  if (Anum === Bnum) return 0

  return Anum > Bnum ? 1 : -1
}
export const isCurrentUser = (sessionEmail = "", targetEmail = "") =>
  sessionEmail &&
  targetEmail &&
  sessionEmail.toLowerCase() === targetEmail.toLowerCase()

export const formatDate = (date, dateFormat, defaultValue = "") => {
  if (date && new Date(date) != "Invalid Date" && !isNaN(new Date(date))) {
    return formatTimeZone(convertToLocalTimeZone(date), dateFormat)
  } else {
    return defaultValue
  }
}

export const getSelectedFilterItem = (filterElement) => {
  const filterValue = []
  filterElement.map(({ disabled, label, type, value }) => {
    const getType = type === "modality" || type === "model"
    const labelType = () => {
      if (getType) {
        return type === "model"
          ? "Model | " + label
          : type.charAt(0).toUpperCase() + type.slice(1) + " | " + label
      }
      return label
    }
    filterValue.push({
      disabled,
      displayLabel: labelType(),
      label: label,
      type,
      value
    })
  })
  return filterValue
}

export const prepareFilterDropdownSupportedValues = (dropDownList = []) => {
  return dropDownList?.map((ele, index) => {
    return {
      id: index + 1,
      label: ele
    }
  })
}

export const convertToServerTimeZone = (
  localDateTime,
  pattern,
  serverTimeZone
) => {
  try {
    if (!localDateTime) {
      localDateTime = new Date()
    }
    if (!serverTimeZone) {
      serverTimeZone = TIME_ZONES.server
    }

    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const utcDate = zonedTimeToUtc(localDateTime, localTimeZone)
    const zonedDate = utcToZonedTime(utcDate, serverTimeZone)
    if (pattern) {
      return formatTimeZone(zonedDate, pattern)
    }

    return zonedDate
  } catch (error) {
    return localDateTime
  }
}

export const convertToLocalTimeZone = (
  serverTime,
  pattern,
  targetTimeZone,
  serverTimeZone
) => {
  try {
    if (!serverTimeZone) {
      serverTimeZone = TIME_ZONES.server
    }
    if (!serverTime) {
      serverTime = utcToZonedTime(new Date(), serverTimeZone)
    }
    let utcDate
    if (serverTime.match(/Z$/)) {
      utcDate = serverTime
    } else {
      utcDate = zonedTimeToUtc(serverTime, serverTimeZone)
    }
    const date = new Date(utcDate)

    if (!targetTimeZone) {
      targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    const zonedDate = utcToZonedTime(date, targetTimeZone)
    if (pattern) {
      return formatTimeZone(zonedDate, pattern)
    }
    return zonedDate
  } catch (error) {
    return serverTime
  }
}

export const softwareStatus = (status, swInProgressStatus) => {
  let transFormSWStatus = formatStatus(status)
  let transFormSWSubStatus = formatStatus(swInProgressStatus)
  if (transFormSWStatus === "INPROGRESS") {
    switch (transFormSWSubStatus) {
    case formatStatus(CONFIG_SW_STATUS.INPROGRESSSTEP1):
      return "inProgressStep1"
    case formatStatus(CONFIG_SW_STATUS.INPROGRESSSTEP2):
      return "inProgressStep2"
    case formatStatus(CONFIG_SW_STATUS.INPROGRESSSTEP3):
      return "inProgressStep3"
    default:
      return "inProgress"
    }
  } else if (transFormSWStatus === "INSTALLED") {
    return "SUCCESS"
  } else {
    return status
  }
}

export const formatStatus = (status) => {
  return status
    ?.replace(/-/g, "")
    ?.replace(/_/g, "")
    ?.replace(/\s/g, "")
    ?.toUpperCase()
}

export const checkForUpdateAction = ({ name, inputValue, error }) =>
  !name || name === inputValue || error?.error

export const checkForCreateAction = ({
  action,
  CREATE,
  name,
  inputValue,
  error
}) => (action === CREATE ? name === inputValue || error?.error : "")

export const sort = (data = [], sortKey = "", direction = "asc") => {
  if (direction == "desc") {
    return data.sort((a, b) => (a[sortKey] < b[sortKey] ? 1 : -1))
  }
  return data.sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : -1))
}
