/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom/dist"

import styles from "./pageLayout.module.scss"
import PageOutlet from "./PageOutlet"

import AppHeader from "../components/AppHeader/AppHeader"
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"
import SideNavigation from "../components/SideNavigation/SideNavigation"
import { BreadcrumbProvider } from "../contexts/breadcrumb/breadcrumb"
import { ROUTE_CONFIG } from "../utils/GlobalConstants"
import { StorageKeys, setSession } from "../utils/SessionHelper/session"
import { classNames } from "../utils/styles/helper"

const PageLayout = () => {
  const queryParams = useParams()
  const location = useLocation()
  const [sideNavExpanded, setSideNavExpanded] = useState(false)

  useEffect(() => {
    const customerId = queryParams[ROUTE_CONFIG.CUSTOMER.param]
    if (customerId && customerId !== "null") setSession(StorageKeys.CUSTOMER_ID, customerId)
  }, [])

  const hideBreadcrumb =
    location.pathname.includes(ROUTE_CONFIG.USER.routeName) ||
    location.pathname.includes(ROUTE_CONFIG.ACCESS_DENIED.routeName)

  const hideSideNav =
    location.pathname.includes(ROUTE_CONFIG.FLEET_ACCESS_DENIED.routeName) ||
    location.pathname.includes(ROUTE_CONFIG.ACCESS_DENIED.routeName) ||
    location.pathname.includes(ROUTE_CONFIG.USER.routeName)

  let sideNavClassName = ""

  if (hideSideNav) {
    sideNavClassName = "hiddenSideNav"
  } else if (sideNavExpanded) {
    sideNavClassName = "sideNavExpand"
  } else {
    sideNavClassName = "sideNavCollapse"
  }

  return (
    <BreadcrumbProvider>
      <div className={ classNames(styles.page, sideNavClassName) }>
        <div className={ styles.header }>
          <AppHeader isSignedIn={ true } />
        </div>
        { !hideBreadcrumb && (
          <div className={ styles.breadcrumbs }>
            <Breadcrumb />
          </div>
        ) }
        { !hideSideNav && (
          <div className={ styles.sideBar }>
            <SideNavigation onCollapseExpand={ setSideNavExpanded } />
          </div>
        ) }
        <div className={ styles.content }>
          <PageOutlet />
        </div>
      </div>
    </BreadcrumbProvider>
  )
}

export default PageLayout
