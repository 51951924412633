/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import style from "./FilterPopup.module.scss"

import { useToaster } from "../../contexts/ToastContext"
import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { useLazyQuery } from "../../utils/CustomHooks/reactQuery"
import { APPLY, CANCEL, COMPARE_VALUE, FILTER_HEADING, RESET, ROUTE_CONFIG } from "../../utils/GlobalConstants"
import MultipleSelectDropdown from "../Dropdown/MultipleSelectDropdown"
import PopupButtonGroup from "../PopupButtonGroup/PopupButtonGroup"

const prepareSelectedFilter = (_filters, _data) => {
  const prevSelectedFilter = {}
  _data?.forEach((option) => {
    const values = _filters
      .filter((filter) => filter.type === option.key)
      .map((filter) => filter.value)
      .filter((value) => value !== 0)
    prevSelectedFilter[option.key] = option.multiple ? values : values[0]
  })
  return prevSelectedFilter
}

const FilterPopup = ({
  data,
  popupStyles,
  onCancel,
  onSubmit,
  filters,
  customLoadingStyle,
  showToastError,
  setFilterAPIError
}) => {
  const { t } = useTranslation(["fleet"])
  const { t: common } = useTranslation(["common"])
  const queryParams = useParams()
  const { displayMediumErrorToast } = useToaster()
  const siteId = queryParams[ROUTE_CONFIG.SITE.param]
  const [filterData, setFilterData] = useState(data)
  const [isFilterModified, setIsFilterModified] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(prepareSelectedFilter(filters, data))

  const onModalityDataLoadCompleted = (_data) => {
    const modalities = _data?.data?.modalities
    setFilterOptionData(modalities, COMPARE_VALUE.modality)
  }

  const {
    data: modalityDropdownList,
    isFetched: isModalityFetched,
    isFetching
  } = useQuery([fleetSVCKeys.MODALITY_VALUES_BY_SITE, siteId], () => fleetGetSVC.getModalityValuesBySite(siteId), {
    onError: () => {
      if (showToastError) displayMediumErrorToast(common("errorInFilterOptionsApi"))
      setFilterAPIError?.(true)
    },
    onSuccess: onModalityDataLoadCompleted
  })

  const modalityDropdownData = modalityDropdownList?.data?.modalities

  const onModelDataLoadCompleted = (_data) => {
    const models = _data?.data?.models
    setFilterOptionData(models, COMPARE_VALUE.model)
  }

  const setFilterOptionData = (_data, compareValue) => {
    const options = _data?.map((ele, index) => {
      return {
        id: index + 1,
        label: compareValue === COMPARE_VALUE.model ? ele.split("|")[1] : ele,
        parent: compareValue === COMPARE_VALUE.model ? ele.split("|")[0] : ele
      }
    })
    if (compareValue === COMPARE_VALUE.modality) {
      const modIdx = filters.findIndex((item) => item.type === COMPARE_VALUE.modality)
      if (modIdx > -1) filters[modIdx].value = options?.find((item) => item.label === filters[modIdx].label)?.id
    } else if (compareValue === COMPARE_VALUE.model) {
      const modelIdx = filters.findIndex((item) => item.type === COMPARE_VALUE.model)
      if (modelIdx > -1) filters[modelIdx].value = options?.find((item) => item.label === filters[modelIdx].label)?.id
    }
    const _filterData = [...filterData]
    const idx = _filterData?.findIndex((item) => item.key === compareValue)
    if (idx > -1) {
      _filterData[idx].options = options
      setFilterData(_filterData)
    }
    if (filters?.length > 0 && !isFilterModified) {
      const preparedFilterData = prepareSelectedFilter(filters, filterData)
      setSelectedFilter((prevState) => {
        return {
          ...prevState,
          ...{ [compareValue]: preparedFilterData[compareValue] }
        }
      })
    }
  }

  const [getModels] = useLazyQuery(
    [fleetSVCKeys.MODEL_VALUES_BY_SITE, siteId],
    () =>
      fleetGetSVC.getModelValuesBySiteAndModality(siteId, modalityDropdownData, selectedFilter[COMPARE_VALUE.modality]),
    {
      onError: () => {
        if (showToastError) displayMediumErrorToast(common("errorInFilterOptionsApi"))
        setFilterAPIError?.(true)
      },
      onSuccess: onModelDataLoadCompleted
    }
  )

  useEffect(() => {
    if (selectedFilter[COMPARE_VALUE.modality]?.length > 0 && isModalityFetched) {
      getModels()
    }
  }, [selectedFilter[COMPARE_VALUE.modality]])

  const handleSelectedItem = (value, key) => {
    setIsFilterModified(true)
    if (key === COMPARE_VALUE.modality) {
      if (value.length === 0) {
        setSelectedFilter({})
      }
      if (JSON.stringify(value) !== JSON.stringify(selectedFilter[key])) {
        setFilterOptionData([], COMPARE_VALUE.model)
        setSelectedFilter((prevState) => {
          return { ...prevState, ...{ [COMPARE_VALUE.model]: [] } }
        })
      }
    }
    setSelectedFilter((prevState) => {
      return { ...prevState, ...{ [key]: value } }
    })
  }

  const handleClose = () => {
    setSelectedFilter({})
    onCancel(false)
  }

  const handleReset = () => {
    const _filterData = { ...selectedFilter }
    Object.keys(_filterData)?.forEach((key) => {
      const isDisabled = filters.find((item) => item.type === key)?.disabled
      if (!isDisabled) _filterData[key] = []
    })
    setSelectedFilter(_filterData)
  }

  const getLabel = (key, value) => {
    const dropdown = filterData.find((forEachOption) => forEachOption.key === key)
    const option = dropdown?.options.find((option) => option.id == value)
    return option?.label
  }

  const getDisabled = (key) => {
    const dropdown = filterData.find((option) => option.key === key)
    return dropdown.disabled
  }

  const getParent = (key, value) => {
    const dropdown = filterData.find((forEachOption) => forEachOption.key === key)
    const option = dropdown?.options.find((option) => option.id == value)
    return option?.parent
  }

  const handleSubmit = () => {
    if (selectedFilter.modality) {
      const newFilters = []
      Object.entries(selectedFilter).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((_val) => {
            newFilters.push({
              disabled: getDisabled(key),
              label: getLabel(key, _val),
              parent: key === COMPARE_VALUE.model ? getParent(key, _val) : "",
              type: key,
              value: _val
            })
          })
        } else {
          newFilters.push({
            disabled: getDisabled(key),
            label: getLabel(key, value),
            parent: key === COMPARE_VALUE.model ? getParent(key, value) : "",
            type: key,
            value
          })
        }
      })
      onSubmit?.(newFilters)
      onCancel(false)
    }
  }

  const isEmptyModality = !selectedFilter.modality || selectedFilter.modality?.length === 0

  return (
    <div className={ style.container }>
      <span className={ style.overlay }></span>
      <PopupButtonGroup
        resetButton={ true }
        confirm={ APPLY }
        cancel={ CANCEL }
        formClose={ !filters.length > 0 }
        reset={ RESET }
        popupStyles={ popupStyles }
        heading={ FILTER_HEADING }
        onCancel={ handleClose }
        onReset={ handleReset }
        onSubmit={ handleSubmit }
        isConfirmEnable={ isEmptyModality }
        disableReset={ isEmptyModality }
        isContentLoading={ isFetching }
        customLoadingStyle={ customLoadingStyle }
      >
        <span className={ style.filterPopupContent }>
          { filterData?.map((option) => {
            const modalityPlaceholder = isEmptyModality ? "" : t("filterPopup.filterBy")
            const placeholder = option.required ? t("filterPopup.selectRequired") : modalityPlaceholder
            return (
              <MultipleSelectDropdown
                key={ option.key }
                { ...option }
                displayLabelText
                required={ Boolean(option.required) }
                width={ 264 }
                placeholder={ placeholder }
                value={ selectedFilter[option?.key] || option.defaultValue }
                menuheight={ { maxHeight: 76 } }
                disabled={ option.disabled || (option.key != COMPARE_VALUE.modality && isEmptyModality) }
                onChange={ (value) => handleSelectedItem(value, option.key) }
              />
            )
          }) }
        </span>
      </PopupButtonGroup>
    </div>
  )
}

FilterPopup.defaultProps = {
  showToastError: true
}

FilterPopup.propTypes = {
  customLoadingStyle: PropTypes.any,
  data: PropTypes.array,
  filters: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  popupStyles: PropTypes.any,
  setFilterAPIError: PropTypes.any,
  showToastError: PropTypes.bool
}

export default FilterPopup
