import { IconButton, Tooltip } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputAdornment from "@mui/material/InputAdornment"
import OutlinedInput from "@mui/material/OutlinedInput"
import PropTypes from "prop-types"
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from "react"

import styles from "./SearchBar.module.scss"

import { SEARCH_INFO, SEARCH_INSTANCES } from "../../utils/Constants/Search"
import {
  COMPARE_VALUE,
  ICON_NAME,
  SEARCH_DEFAULT_MIN_CHARACTERS,
  specialCharactersNotAllowedForSearch
} from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"

// eslint-disable-next-line react/display-name,
const SearchBar = forwardRef(
  (
    { type, inputStyles, onChange, onHandleSubmit, isDisabled, ...props },
    ref
  ) => {
    const [searchInput, setSearchInput] = useState(null)
    const [inputState, setInputState] = useState(null)

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
      reset: () => {
        setSearchInput(null)
      }
    }))

    const handleOnChange = (e) => {
      const value = e.target.value.replace(specialCharactersNotAllowedForSearch, "")
      setSearchInput(value)
      onChange?.(value)
    }

    const setHandleSubmit = (value, closeAction) => {
      setSearchInput(closeAction ? null : value)
      if (closeAction) {
        onHandleSubmit?.(value, true, false)
      } else if (
        value.length < (props.minCharacters || SEARCH_DEFAULT_MIN_CHARACTERS)
      ) {
        onHandleSubmit?.(value, false, true)
      } else {
        onHandleSubmit?.(value, false, false)
      }
      inputRef.current.blur()
    }

    const placeholder = searchInput === null ? SEARCH_INFO()[type]?.info : ""

    return (
      <Tooltip
        arrow
        style={ { cursor:"pointer" } }
        title={ !isDisabled && SEARCH_INFO()[type]?.tooltipTitle }
        classes={ { tooltip: "searchPlaceholderTooltip" } }
        placement="bottom-start"
        disableFocusListener
      >
        <FormControl variant="outlined">
          <OutlinedInput
            className={ "noError" }
            inputRef={ inputRef }
            onFocus={ () => setInputState("focus") }
            onBlur={ () => setInputState(null) }
            data-testid="search-input"
            placeholder={ placeholder }
            sx={ { ...inputStyles } }
            onChange={ handleOnChange }
            onKeyDown={ (e) =>
              e.key === COMPARE_VALUE.enter && setHandleSubmit(e.target.value)
            }
            value={ searchInput || "" }
            disabled={ isDisabled }
            { ...props }
            endAdornment={
              <InputAdornment position="end">
                { searchInput !== null || inputState === "focus" ? (
                  <IconButton
                    onClick={ () => setHandleSubmit("", true) }
                    aria-label="Clear search"
                    name="clear"
                  >
                    <Icon icon={ ICON_NAME.bgClose } iconStyle={ styles.icon } />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled
                    onClick={ () => setHandleSubmit("", true) }
                  >
                    <Icon
                      icon={
                        isDisabled ? ICON_NAME.searchDisabled : ICON_NAME.search
                      }
                      iconStyle={ styles.icon }
                    />
                  </IconButton>
                ) }
              </InputAdornment>
            }
          />
        </FormControl>
      </Tooltip>
    )
  }
)

SearchBar.propTypes = {
  inputStyles: PropTypes.any,
  isDisabled: PropTypes.bool,
  minCharacters: PropTypes.number,
  onChange: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  type: PropTypes.oneOf([
    SEARCH_INSTANCES.assetFaults,
    SEARCH_INSTANCES.assetProperties,
    SEARCH_INSTANCES.assetStatus,
    SEARCH_INSTANCES.centralSoftware,
    SEARCH_INSTANCES.centralConfig,
    SEARCH_INSTANCES.centralConfiguration,
    SEARCH_INSTANCES.centralConfigDeployment,
    SEARCH_INSTANCES.customerView,
    SEARCH_INSTANCES.siteView,
    SEARCH_INSTANCES.reportsConfiguration,
    SEARCH_INSTANCES.userActivity,
    SEARCH_INSTANCES.siteNavigatorSearch,
    SEARCH_INSTANCES.fleetView,
    SEARCH_INSTANCES.subFleetView,
    SEARCH_INSTANCES.subFleet,
    SEARCH_INSTANCES.userManagement,
    SEARCH_INSTANCES.manageAccess,
    SEARCH_INSTANCES.reportsSWPackage
  ])
}

export default SearchBar
