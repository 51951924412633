/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query"

import NotesReceived from "./internals/NotesReceived"
import NotesSend from "./internals/NotesSend"
import styles from "./NotesPopUp.module.scss"

import { useAuthContext } from "../../contexts/Auth/auth"
import { faultSVC } from "../../services/reactQueries/faultsvc"
import { encodeURIString } from "../../utils/Common/common"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import {
  CLOSE,
  ICON_NAME,
  NOTES,
  TEXT_FIELD_MAX_CHAR,
  specialCharactersNotAllowedForSearch
} from "../../utils/GlobalConstants"
import { convertToServerTimeZone, isCurrentUser } from "../../utils/helper"
import { removeEmoji } from "../../utils/InputValidator/validator"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Icon from "../Icon/Icon"
import Loading from "../Loading/Loading"

const NotesPopUp = ({ notesPopUpState, handleClose, queryKey }) => {
  const { getTokenData } = useAuthContext()
  const { t } = useTranslation("assetFault")
  const queryClient = useQueryClient()
  const userData = useMemo(() => getTokenData(), [])
  const chatCardRef = useRef(null)
  const [refetchWhenClose, setRefetchWhenClose] = useState(false)

  const onGetNotesCompleted = (data) => {
    const notes = data?.data?.notes || []
    setNotesToBeSendData(notes)
  }

  const { isFetching }  = useQuery(
    ["assetFaultsNotes", notesPopUpState?.faultId],
    () =>
      faultSVC.getAssetFaultsNotesByFaultID(notesPopUpState?.faultId),
    { onSuccess: onGetNotesCompleted }
  )

  const [textMsg, setTextMsg] = useState("")
  const [notesToBeSendData, setNotesToBeSendData] = useState([])

  const { requestMutation: addNotesMutation } = useMutationWithHandlers({
    queryFn: faultSVC.postAssetFaultsNotesByFaultID
  })

  useEffect(() => {
    setTimeout(() => {
      chatCardRef?.current?.lastElementChild?.scrollIntoView({
        block: "center"
      })
    })
  }, [isFetching])

  const handleSend = () => {
    const noteText = textMsg?.trim()
    addNotesMutation({ payload:{ faultId:notesPopUpState?.faultId, note: encodeURIString(noteText) } })
    const newNote = {
      note: noteText,
      timestamp: convertToServerTimeZone(),
      username: userData.email
    }
    const notesData = [...notesToBeSendData]
    notesData.push(newNote)
    setNotesToBeSendData(notesData)
    setRefetchWhenClose(true)
    setTextMsg("")
    setTimeout(() => {
      chatCardRef?.current?.lastElementChild?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      })
    })
  }

  const handleOnChangeTextMsg = (event) => {
    const value = event.target.value.replace(specialCharactersNotAllowedForSearch, "")
    let msg = removeEmoji(value)
    setTextMsg(msg)
  }

  const onClose = () => {
    if (refetchWhenClose) queryClient.refetchQueries({ queryKey, type: "active" })
    handleClose(refetchWhenClose)
  }

  return (
    <ConfirmationPopup
      heading={ NOTES }
      isOpen={ notesPopUpState?.isOpen }
      onSubmit={ handleSend }
      onCancel={ onClose }
      cancel={ CLOSE }
      popupStyles={ styles.popUpContainer }
      isSubmit={ false }
    >

      <div className={ styles.contentContainer }>
        { isFetching ? <Loading /> :<>
          <div className={ styles.chatBoxContainer } ref={ chatCardRef }>
            { notesToBeSendData?.map((data) => {
              if(isCurrentUser(userData.email, data.username))
                return (
                  <div key={ data.timestamp } className={ styles.chatSentContainer }>
                    <NotesSend data={ data } />
                  </div>
                )
              else
                return (
                  <div key={ data.timestamp } className={ styles.chatReceivedContainer }>
                    <NotesReceived data={ data } />
                  </div>
                )
            }) }
          </div>
          <div className={ styles.textContainer }>
            <textarea
              className={  classNames("textArea", styles.inputText) }
              placeholder={ t("notesPopUp.placeholder") }
              maxLength="256"
              onChange={ (event) => handleOnChangeTextMsg(event) }
              value={ textMsg }
              data-testid="textField"
            />
            <div className={ styles.labelButtonContainer }>
              <span className={ styles.labelText }>
                { t("notesPopUp.maxLength", {
                  maxLength: TEXT_FIELD_MAX_CHAR
                }) }
              </span>
              <IconButton
                onClick={ handleSend }
                className={ styles.sendButton }
                data-testid="sendButton"
                disabled={ textMsg?.trim().length === 0 }
              >
                <Icon
                  icon={
                    textMsg?.trim().length > 0
                      ? ICON_NAME.sendEnabled
                      : ICON_NAME.sendDisabled
                  }
                />
              </IconButton>
            </div>
          </div>
        </> }
      </div>
    </ConfirmationPopup>
  )
}

NotesPopUp.propTypes = {
  handleClose: PropTypes.func,
  notesPopUpState: PropTypes.object,
  queryKey: PropTypes.any
}

export default NotesPopUp
